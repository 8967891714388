import AuthModule from '../utils/Auth/authModule.js'
import authOptions from '../utils/Auth/authConfig.js'
import { dspConfig } from '../config/dspConfig'
import { pageGuardTester } from '../config/pageGuard'
import VueRouter from 'vue-router'
import { surcoucheConfig } from '@/loadConfig'

export const authModule = new AuthModule(authOptions, process.env.VUE_APP_ENV)

function loadView (view) {
  return () => import(`./views/${view}`)
}

export const routes = [
  {
    name: 'keystoneV2',
    path: '/keystone_v2/overview',
    component: loadView('Keystone_v2'),
    props: true,
    beforeEnter: authModule.callAuthApi.bind(authModule)
  },
  {
    name: 'newFeatureRefsManager',
    path: '/keystone_v2/new_feature_refs_manager',
    component: loadView('NewFeatureRefsManager'),
    props: true,
    beforeEnter: authModule.callAuthApi.bind(authModule)
  },
  {
    name: 'userUi',
    path: '/users/manage',
    component: loadView('UserUi'),
    props: true,
    beforeEnter: authModule.callAuthApi.bind(authModule)
  },
  {
    name: 'keystoneTools',
    path: '/keystone/tool',
    component: loadView('KeystoneTools'),
    props: true,
    beforeEnter: authModule.callAuthApi.bind(authModule)
  },
  {
    name: 'keystoneTool',
    path: '/keystone/tool/:dsp/:tool',
    component: loadView('KeystoneTool'),
    props: true,
    beforeEnter: authModule.callAuthApi.bind(authModule)
  },
  {
    name: 'billingEntities',
    path: '/keystone_v2/billing_entities',
    component: loadView('BillingEntities'),
    props: true,
    beforeEnter: authModule.callAuthApi.bind(authModule)
  },
  {
    name: 'thirdPartyFeed',
    path: '/third_party_feed',
    component: loadView('ThirdPartyFeed'),
    props: true,
    beforeEnter: authModule.callAuthApi.bind(authModule)
  },
  {
    name: 'proactiveSupportIO',
    path: '/keystone_v2/proactiveSupportIO',
    component: loadView('ProactiveSupportIO.vue'),
    props: true,
    beforeEnter: authModule.callAuthApi.bind(authModule)
  },
  {
    name: 'userRightsViewer',
    path: '/keystone_v2/user_rights_viewer',
    component: loadView('UserRightsViewer'),
    props: true,
    beforeEnter: authModule.callAuthApi.bind(authModule)
  },
  {
    name: 'cuicuiDemoManage',
    path: '/cuicui/demo/manage',
    component: loadView('CuicuiDemoManager'),
    props: true,
    beforeEnter: authModule.callAuthApi.bind(authModule)
  },
  {
    name: 'alertingsMain',
    path: '/alertings/main',
    component: loadView('Alertings'),
    props: true,
    beforeEnter: authModule.callAuthApi.bind(authModule)
  },
  {
    name: 'alertingsIndex',
    path: '/alertings/index',
    component: loadView('AlertingsIndex'),
    props: true,
    beforeEnter: authModule.callAuthApi.bind(authModule)
  },
  {
    name: 'callback',
    path: '/callback',
    component: loadView('Callback')
  },
  {
    name: 'logout',
    path: '/Logout',
    component: loadView('Logout')
  },
  {
    name: 'interface5000',
    path: '/interface5000',
    component: loadView('Interface5000'),
    beforeEnter: authModule.callAuthApi.bind(authModule)
  },
  {
    name: 'maintenance',
    path: '/maintenance',
    component: loadView('Maintenance')
  },
  {
    name: 'models',
    path: '/models/:dsp/:instructionStratId',
    component: loadView('Model'),
    beforeEnter: authModule.callAuthApi.bind(authModule)
  },
  {
    name: 'table',
    path: '/:dsp/:openDialog?/:dialogId?',
    component: loadView('Table'),
    props: true,
    beforeEnter: authModule.callAuthApi.bind(authModule)
  },
  {
    name: 'index',
    path: '/',
    component: loadView('Index'),
    beforeEnter: authModule.callAuthApi.bind(authModule)
  }
]

const isOnMaintenanceFunc = () => {
  return surcoucheConfig.isConfigLoaded && surcoucheConfig.getters.ON_MAINTENANCE
}

export let router = new VueRouter({ mode: 'history', routes: routes })

/**
 * allow access to the routes depending of the environment
 */
router.beforeEach((to, from, next) => {
  const treatPath = (path) => {
    // @ts-ignore
    const title = path.replace(/\//g, ' ').trim().ucwords()
    // eslint-disable-next-line no-extra-boolean-cast
    return !!title ? `${title} - ` : ''
  }
  document.title = `${treatPath(to.path)}${process.env.VUE_APP_TITLE}`

  let paramToTest = ''
  /**
   * Test if a dsp is configured in dspConfig before try to access the page
   * @param testDsp {string}
   * @returns {boolean}
   */
  const dspTester = function (testDsp) {
    const dspList = Object.keys(dspConfig)
    return dspList.indexOf(testDsp) !== -1
  }

  if (isOnMaintenanceFunc()) {
    if (to.path !== '/maintenance') {
      next({ path: '/maintenance' })
    } else {
      next()
    }
  } else {
    if (to.path === '/maintenance') {
      next({ path: '/' })
    }

    if (to.path === '/interface5000') {
      next()
    } else if (to.name === 'table') {
      paramToTest = to.params.dsp
      if (!dspTester(paramToTest)) {
        console.warn(`The dsp ${paramToTest} is not configured in dspConfig. Redirect to home page...`)
        next({ path: '/' })
      }
    } else { // alertings
      paramToTest = to.name
    }

    if (pageGuardTester(paramToTest)) {
      next()
    } else {
      next({ path: '/' })
    }
  }
})
