import { processHostEnv } from '../../envHelper'

const apiConfig = {
  server: processHostEnv(process.env.VUE_APP_API_HOST),
  calls: {
    alerts: {
      get: '/alerts'
    },
    alertsSummary: {
      get: '/alertsSummary'
    },
    alertsIndex: {
      get: '/alertsIndex'
    },
    groupKeys: {
      get: '/group_keys',
      put: '/group_keys'
    },
    cancelAlertBrief: {
      post: '/alerts/cancel_alert_brief'
    },
    cancelAlertBulkAddBrief: {
      post: '/alerts/cancel_alert_bulk_add_brief'
    },
    snoozeAlert: {
      post: '/alerts/snooze'
    },
    instruction: {
      get: '/instruction',
      put: '/instruction',
      delete: '/instruction'
    },
    new_instruction: {
      post: '/new_instruction'
    },
    instructions: {
      get: '/instructions',
      post: '/instructions'
    },
    bucketsInstructions: {
      put: '/buckets_edit'
    },
    users: {
      get: '/users',
      post: '/users'
    },
    user: {
      get: '/user',
      put: '/user',
      delete: '/user'
    },
    usersnap_credentials: {
      get: '/usersnap_credentials'
    },
    instructionsStrat: {
      get: '/instructions_strat'
    },
    modelStrat: {
      get: '/model'
    },
    metaHoldings: {
      get: '/meta_holdings',
      post: '/meta_holdings'
    },
    metaHolding: {
      get: '/meta_holding',
      put: '/meta_holding'
    },
    holdings: {
      get: '/holdings',
      post: '/holdings'
    },
    holding: {
      get: '/holding',
      put: '/holding'
    },
    companies: {
      get: '/companies',
      post: '/companies'
    },
    company: {
      get: '/company',
      put: '/company'
    },
    sieges: {
      get: '/sieges',
      post: '/sieges'
    },
    members: {
      get: '/members',
      post: '/members'
    },
    siegeRules: {
      get: '/siege_rules',
      post: '/siege_rules'
    },
    advertiser: {
      get: '/advertiser'
    },
    advertisers: {
      get: '/advertisers'
    },
    overviews: {
      get: '/overviews'
    },
    dspConfigs: {
      get: '/keystone/dsp_configs'
    },
    remoteJobs: {
      get: '/remote_job_service/jobs',
      post: '/remote_job_service/jobs'
    },
    surcoucheConfig: {
      get: '/surcouche_config'
    },
    userRoles: {
      get: '/user/<replace_id>/roles',
      put: '/user/<replace_id>/roles',
      delete: '/user/<replace_id>/roles'
    },
    roleUsers: {
      get: '/users_role'
    },
    roles: {
      get: '/roles'
    },
    dv360AccessLLD: {
      post: '/dv360/access_lld'
    },
    TTDAccessLLD: {
      post: '/ttd/access_lld'
    },
    MediamathAccessLLD: {
      post: '/mediamath/access_lld'
    },
    ReplayInstruStratError: {
      post: '/generic/replay_instru_strat_error'
    },
    AppnexusSplitAudience: {
      get: '/appnexus/split_audience',
      put: '/appnexus/split_audience'
    },
    projectConfigStatus: {
      get: '/project_config',
      put: '/project_config'
    },
    serviceAccountCreation: {
      get: '/service_account_creation'
    },
    serviceAccount: {
      post: '/service_account/dbm'
    },
    TheTradeDeskLldVersions: {
      get: '/ttd/lld_versions'
    },
    demos: {
      get: '/manage_demo/demos',
      post: '/manage_demo/demos'
    },
    resetDemo: {
      put: '/manage_demo/demo/<replace_id>/reset'
    },
    demo: {
      delete: '/manage_demo/demo'
    },
    keystoneSiegeSettings: {
      get: '/keystone_v2/keystone_siege_settings',
      post: '/keystone_v2/keystone_siege_settings'
    },
    keystoneMemberSettings: {
      get: '/keystone_v2/keystone_member_settings',
      put: '/keystone_v2/keystone_member_settings'
    },
    keystoneSiegeSetting: {
      get: '/keystone_v2/keystone_siege_setting',
      put: '/keystone_v2/keystone_siege_setting'
    },
    keystoneMemberSetting: {
      get: '/keystone_v2/keystone_member_setting',
      put: '/keystone_v2/keystone_member_setting'
    },
    countries: {
      get: '/keystone_v2/countries'
    },
    collaborators: {
      get: '/keystone_v2/collaborators'
    },
    collaboratorsOfSiege: {
      get: '/keystone_v2/siege/<replace_id>/collaborators'
    },
    siegeRulesOfSiege: {
      get: '/keystone_v2/siege/<replace_id>/siege_rules'
    },
    advertisersOfMember: {
      get: '/keystone_v2/member/<replace_id>/advertisers'
    },
    siegeRuleChecker: {
      get: '/keystone_v2/service/siege_rule_checker'
    },
    billingEntities: {
      get: '/keystone_v2/billing/billing_entities'
    },
    billingAccount: {
      get: '/keystone_v2/billing/<entity_type>/<entity_id>/billing_account'
    },
    reverseBillingAccountEntities: {
      get: '/keystone_v2/billing/reverse/<entity_type>/<entity_id>'
    },
    brief: {
      get: '/brief/external_id/<external_id>/dsp/<dsp>'
    },
    acknowledgments: {
      get: '/brief/<replace_id>/acknowledgments'
    },
    acknowledgment: {
      put: '/acknowledgment/<replace_id>'
    },
    instruNotes: {
      get: '/instruction_notes/external_id/<external_id>/dsp/<dsp>',
      post: '/instruction_notes/external_id/<external_id>/dsp/<dsp>',
      delete: '/instruction_note'
    },
    autoplugs: {
      get: '/company/<replace_id>/autoplugs',
      post: '/company/<replace_id>/autoplugs'
    },
    autoplug: {
      put: '/autoplug/<replace_id>',
      delete: '/autoplug'
    },
    statusLogs: {
      get: '/overviews/<replace_id>/status_logs'
    },
    logs: {
      get: '/logs/<entity_type>/<entity_id>'
    },
    newFeatureReference: {
      get: '/keystone_v2/new_feature_reference/<replace_id>',
      put: '/keystone_v2/new_feature_reference/<replace_id>'
    },
    newFeatureReferenceMembers: {
      get: '/keystone_v2/new_feature_reference/<replace_id>/members'
    },
    newFeatureReferences: {
      get: '/keystone_v2/new_feature_references',
      post: '/keystone_v2/new_feature_references'
    },
    newFeatureReferenceUsageForEntity: {
      get: '/keystone_v2/new_feature_reference/<application>/<entity_id>/dev_name/<dev_name>'
    },
    newFeatureReferencesForEntityWithDsp: {
      get: '/keystone_v2/new_feature_references/<application>/<entity_id>/dsp/<dsp>'
    },
    bulkAddBriefs: {
      get: '/bulk_add/<replace_id>/briefs'
    },
    overviewAttributes: {
      get: '/overview_attribute/external_id/<external_id>/dsp/<dsp>'
    },
    thirdPartyFeeds: {
      get: '/third_party_feeds'
    },
    thirdPartyFeed: {
      get: '/third_party_feed',
      post: '/third_party_feed'
    },
    thirdPartyLocation: {
      get: '/third_party_location'
    },
    thirdPartyFile: {
      get: '/third_party_file',
      post: '/third_party_file'
    },
    setupTransformations: {
      post: '/setup_transformations'
    },
    validateThirdPartySql: {
      post: '/validate_third_party_sql'
    },
    thirdPartyRefGranularity: {
      get: '/third_party_ref_granularity'
    },
    thirdPartyRefEvents: {
      get: '/third_party_ref_events'
    },
    thirdPartyRefEventTypes: {
      get: '/third_party_ref_event_types'
    },
    thirdPartySimulate: {
      post: '/third_party_simulate'
    },
    thirdPartyTransformations: {
      post: '/third_party_transformations'
    },
    activateThirdPartyFeed: {
      post: '/activate_third_party_feed'
    },
    checkPreMepResult: {
      get: '/check_premep/results/external_id/<external_id>/sub_dsp/<sub_dsp>'
    },
    create_insight: {
      post: '/create_insight'
    },
    createDeckABTest: {
      post: '/create_deck_ab_test'
    },
    createTroubleshooting: {
      post: '/create_troubleshooting'
    },
    baseline: {
      get: '/overviews/<overview_id>/baselines',
      put: '/overviews/<replace_id>/baselines'
    },
    baselines: {
      get: '/baselines'
    },
    removeScibids: {
      post: '/v1/remove_scibids/dsp/<dsp>/member/<member_ext_id>/insertion_order/<io_id>'
    },
    removeScibidsInfo: {
      get: '/v1/remove_scibids/dsp/<dsp>/member/<member_ext_id>/insertion_order/<io_id>'
    },
    removeScibidsInfoDetails: {
      get: '/v1/remove_scibids/dsp/<dsp>/member/<member_ext_id>/insertion_order/<io_id>/job/<job_id>'
    },
    removeScibidsDelete: {
      delete: '/v1/remove_scibids/dsp/delete_job/<job_id>'
    },
    pivotVariables: {
      get: '/third_party_gen/pivot_variables/<sub_dsp>'
    },
    eventsOfIo: {
      get: '/third_party_gen/events/overview/<overview_id>'
    },
    checkPreMepInstructionsValidator: {
      post: '/check_premep/sub_dsp/<sub_dsp>/instructions/validate'
    },
    proactive_ios: {
      get: '/keystone_v2/proactive_io',
      post: '/keystone_v2/proactive_io'
    },
    proactive_io: {
      get: '/keystone_v2/proactive_io',
      put: '/keystone_v2/proactive_io',
      delete: '/keystone_v2/proactive_io'
    },
    proactive_io_comments: {
      get: '/keystone_v2/proactive_io/<proactive_io_id>/comments',
      post: '/keystone_v2/proactive_io/<proactive_io_id>/comments'
    },
    proactive_io_comment: {
      put: '/keystone_v2/proactive_io/<proactive_io_id>/comment/<comment_id>',
      delete: '/keystone_v2/proactive_io/<proactive_io_id>/comment/<comment_id>'
    },
    allowed_auth_domains: {
      get: '/allowed_auth_domains'
    },
    orga_allowed_auth_domains: {
      get: '/allowed_auth_domains/organization/<orga>'
    },
    coldStartAvailability: {
      get: '/overviews/<overview_id>/coldstart_availability'
    },
    overwritesBulkInstructions: {
      post: '/instructions/batch/overwrites/<sub_dsp>'
    },
    userRightsOnEntity: {
      get: '/user_rights/entity/<entity_type>/<entity_id>'
    },
    userRightsOfUser: {
      get: '/user_rights/user/<user_mail>'
    },
    simulateOverwritesBulkInstructions: {
      post: '/instructions/batch/overwrites/<sub_dsp>/simulate'
    },
    surcoucheForcedRatio: {
      get: '/surcouche_forced_ratio/dsp/<dsp>/overview/<overview_external_id>/forced_ratio/<transaction_id>'
    },
    surcoucheForcedRatios: {
      get: '/surcouche_forced_ratios/dsp/<dsp>/member/<member_external_id>/overview/<overview_external_id>',
      post: '/surcouche_forced_ratios/dsp/<dsp>/member/<member_external_id>/overview/<overview_external_id>'
    },
    surcoucheForcedRatioDspConfig: {
      get: '/surcouche_forced_ratio/dsp/<dsp>/config'
    },
    surcoucheForcedRatioAuthorizedDsps: {
      get: '/surcouche_forced_ratio/dsp'
    },
    surcoucheForcedRatioActivation: {
      put: '/surcouche_forced_ratio/dsp/<dsp>/member/<member_external_id>/overview/<overview_external_id>/activation'
    },
    kpiTypes: {
      get: '/third_party_gen/kpi_types'
    },
    availableKpis: {
      get: '/third_party_gen/available_kpis/overview/<overview_id>'
    }
  }
}

export default apiConfig
