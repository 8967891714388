/**
 * Configure here the enabled route in function of the environment
 */
import { $THETRADEDESK, $APPNEXUS, $BEESWAX, $DBM, $MEDIAMATH, $YOUTUBE, $FACEBOOK, $KAYZEN, $META } from './dspConfig'

export const pageGuard = {
  [$APPNEXUS]: {
    isDisabled: {
      development: false,
      staging: false,
      production: false,
      sandbox: false
    }
  },
  [$MEDIAMATH]: {
    isDisabled: {
      development: true,
      staging: true,
      production: true,
      sandbox: true
    }
  },
  [$DBM]: {
    isDisabled: {
      development: false,
      staging: false,
      production: false,
      sandbox: false
    }
  },
  [$THETRADEDESK]: {
    isDisabled: {
      development: false,
      staging: false,
      production: false,
      sandbox: false
    }
  },
  [$YOUTUBE]: {
    isDisabled: {
      development: false,
      staging: false,
      production: false,
      sandbox: false
    }
  },
  [$BEESWAX]: {
    isDisabled: {
      development: false,
      staging: false,
      production: false,
      sandbox: false
    }
  },
  [$FACEBOOK]: {
    isDisabled: {
      development: true,
      staging: true,
      production: true,
      sandbox: true
    }
  },
  [$KAYZEN]: {
    isDisabled: {
      development: true,
      staging: true,
      production: true,
      sandbox: true
    }
  },
  [$META]: {
    isDisabled: {
      development: false,
      staging: false,
      production: false,
      sandbox: false
    }
  },
  alertingsMain: {
    isDisabled: {
      development: false,
      staging: false,
      production: false,
      sandbox: false
    }
  },
  alertingsIndex: {
    isDisabled: {
      development: false,
      staging: false,
      production: false,
      sandbox: false
    }
  },
  createEntity: {
    isDisabled: {
      development: false,
      staging: false,
      production: false,
      sandbox: false
    }
  },
  keystoneOverview: {
    isDisabled: {
      development: false,
      staging: false,
      production: false,
      sandbox: false
    }
  },
  userUi: {
    isDisabled: {
      development: false,
      staging: false,
      production: false,
      sandbox: false
    }
  },
  cuicuiDemoManage: {
    isDisabled: {
      development: false,
      staging: false,
      production: false,
      sandbox: false
    }
  },
  thirdPartyFeed: {
    isDisabled: {
      development: false,
      staging: false,
      production: false,
      sandbox: false
    }
  },
  keystoneV2: {
    isDisabled: {
      development: false,
      staging: false,
      production: false,
      sandbox: false
    }
  }
}

/**
 * look in the pageGuard (config/pageGuard.js) if the page is allowed in function of the environment
 * @param testParam
 * @returns {boolean}
 */
export const pageGuardTester = function (testParam) {
  let env = process.env.VUE_APP_ENV
  return pageGuard[testParam] === undefined || pageGuard[testParam].isDisabled[env] === false
}
