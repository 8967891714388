import {
  $APPNEXUS,
  $BEESWAX,
  $DBM,
  $FACEBOOK, $KAYZEN,
  $MEDIAMATH, $META, $OPTIMIZE_ADGROUPS, $OPTIMIZE_ADSETS, $OPTIMIZE_CAMPAIGNS,
  $OPTIMIZE_LINEITEMS, $OPTIMIZE_STRATEGIES,
  $THETRADEDESK,
  $YOUTUBE, dspConfig
} from '../config/dspConfig'
import { ALL, ALLBUT, ONLY } from './BusinessRulesChecker/OptimizeRulesChecker'

/**
 * return the 'li' field to target in function of the dsp
 * @returns {string}
 */
export function getRightOptimizeField (dsp) {
  let field = ''

  if ([$YOUTUBE, $DBM, $BEESWAX, $APPNEXUS].includes(dsp)) {
    field = $OPTIMIZE_LINEITEMS
  } else if (dsp === $THETRADEDESK) {
    field = $OPTIMIZE_ADGROUPS
  } else if (dsp === $MEDIAMATH) {
    field = $OPTIMIZE_STRATEGIES
  } else if ([$FACEBOOK, $META].includes(dsp)) {
    field = $OPTIMIZE_ADSETS
  } else if (dsp === $KAYZEN) {
    field = $OPTIMIZE_CAMPAIGNS
  }

  return field
}

export function isOptimizeValueAllBut (optimizeValue) {
  return typeof optimizeValue === 'string' && optimizeValue.indexOf(ALLBUT) === 0
}

export function isOptimizeValueOnly (optimizeValue) {
  return typeof optimizeValue === 'string' && optimizeValue.indexOf(ONLY) === 0
}

export function isOptimizeValueAll (optimizeValue) {
  return typeof optimizeValue === 'string' && optimizeValue.trim() === ALL
}

/**
 * @returns {String} : the client field in function of the dsp. member_id | partner_id | organization_id
 */
export function getClientValue (dsp) {
  return dspConfig[dsp.toLowerCase()].client_value
}

/**
 * @param dsp {String}
 * @param alert {Boolean}
 * @returns {String} : the insertion order field in function of the dsp
 */
export function getIoField (dsp, alert = false) {
  if (alert) {
    return dspConfig[dsp.toLowerCase()].ioFieldAlert
  }
  return dspConfig[dsp.toLowerCase()].ioField
}

/**
 * @param dsp {String}
 * @returns {String} : the insertion order field in function of the dsp
 */
export function getNewIoField (dsp) {
  return dspConfig[dsp.toLowerCase()].newIoField
}

/**
 * return the insertion order for an io
 * for beeswax io,
 * add the buzzkey and the campaign_id for having the ful ioId
 * @param instruction
 * @param dsp
 */
export function getFullInsertionOrderOfInstruction (instruction, dsp) {
  const ioField = getIoField(dsp)
  let ioId = instruction[ioField]

  if (dsp === $BEESWAX) {
    const clientField = getClientValue(dsp)
    const clientId = instruction[clientField]
    ioId = recomposeIoIdBeeswax(clientId, ioId)
  }

  return ioId
}

export function recomposeIoIdBeeswax (clientId, ioId) {
  return `${clientId}_${ioId}`
}

export function decomposeIoIdBeeswax (clientId, composedIoId) {
  return composedIoId.split('_')[1]
}

export function recomposeAdvertiserIdBeeswax (clientId, advertiserId) {
  return `${clientId}_${advertiserId}`
}
